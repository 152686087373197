import '../../style/dialog-form.css';

import { Fragment, useState } from 'react';

import IntegrationsSidebar from './IntegrationsSidebar';
import {
  CollapsibleSection,
  DropdownField,
  InlineTooltip,
  PropertyLabel,
  TextField
} from '../Core';
import useIntegrations from './useIntegrations';
import { useParams } from 'react-router-dom';
import { ALL_INTEGRATIONS_MAP, INTEGRATIONS } from './types';
import styles from './styles.module.scss';
import { produce } from 'immer';
import { FieldSelectorWithModal } from '../Modals';
import classNames from 'classnames';
import { PlusIcon, TrashIcon } from '../Icons';
import { API_BASE } from '../../api/utils';
import { useAppSelector } from '../../hooks';
import { getPanelSlug } from '../../utils/panel';

function PersonaSettingsSidebar() {
  const { formId } = useParams<{ formId: string }>();
  const panel = useAppSelector((state) => state.panels.panels[formId]);
  const liveSlug = getPanelSlug(panel, 'live');

  // Global/cached integration settings
  const integration = useIntegrations({
    type: INTEGRATIONS.PERSONA,
    panelId: formId,
    includeInactive: true
  });

  const meta = integration?.data.metadata;
  // Local/draft integration settings
  const [templateId, setTemplateId] = useState(meta.template_id ?? '');
  const [environmentId, setEnvironmentId] = useState(meta.environment_id ?? '');
  const [personaPrefillMap, setPersonaPrefillMap] = useState(
    (meta.prefill_map ?? []).length > 0 ? meta.prefill_map : [defaultEntry()]
  );
  const [personaSaveMap, setPersonaSaveMap] = useState(
    (meta.save_map ?? []).length > 0 ? meta.save_map : [defaultEntry()]
  );

  const [isPartial, setIsPartial] = useState(false);
  const [savePartial, setSavePartial] = useState(false);
  const [prefillPartial, setPrefillPartial] = useState(false);

  function onSubmitCustom(newIsActive: boolean) {
    const filteredPrefill = personaPrefillMap.filter(
      (f: any) => f.persona_attribute || f.feathery_field
    );
    const filteredSave = personaSaveMap.filter(
      (f: any) => f.persona_attribute || f.feathery_field
    );

    if (newIsActive) {
      let partial = filteredPrefill.some(
        ({ persona_attribute, feathery_field }: any) =>
          !persona_attribute || !feathery_field
      );
      if (partial) {
        setPrefillPartial(partial);
        return;
      }

      partial = filteredSave.some(
        ({ persona_attribute, feathery_field }: any) =>
          !persona_attribute || !feathery_field
      );
      if (partial) {
        setSavePartial(partial);
        return;
      }

      partial = !templateId || !environmentId;
      setIsPartial(partial);
      if (partial) return;
    }

    return {
      isUpdate: integration?.data,
      metadata: {
        template_id: templateId,
        environment_id: environmentId,
        prefill_map: filteredPrefill,
        save_map: filteredSave
      }
    };
  }

  const updatePersonaPrefillMap = (newMap: any) => {
    setPersonaPrefillMap(
      produce(newMap, (draft: any) => {
        if (draft.length === 0) draft.push(defaultEntry());
      })
    );
  };

  const updatePersonaSaveMap = (newMap: any) => {
    setPersonaSaveMap(
      produce(newMap, (draft: any) => {
        if (draft.length === 0) draft.push(defaultEntry());
      })
    );
  };

  const sections = [
    {
      title: 'Prefill Persona Flow',
      update: updatePersonaPrefillMap,
      map: personaPrefillMap,
      options: PERSONA_PREFILL_PARAMS,
      partial: prefillPartial,
      tooltip: ''
    },
    {
      title: 'Save Persona Data',
      update: updatePersonaSaveMap,
      map: personaSaveMap,
      options: PERSONA_SAVE_PARAMS,
      partial: savePartial,
      tooltip: `You must set up a webhook in your Persona account to https://${API_BASE}.feathery.io/api/persona/webhook/${liveSlug}/. Enable the inquiry and verification related events, and make sure formatting is kebab case.`
    }
  ];

  return (
    <IntegrationsSidebar
      integrationInfo={ALL_INTEGRATIONS_MAP[INTEGRATIONS.PERSONA]}
      isPartial={isPartial}
      onSubmitCustom={onSubmitCustom}
    >
      <form>
        <div>
          <PropertyLabel label='Template ID' />
          <TextField
            className='dialog-form-input'
            value={templateId}
            onChange={setTemplateId}
            error={isPartial && !templateId}
          />
        </div>
        <div>
          <PropertyLabel label='Environment ID' />
          <TextField
            className='dialog-form-input'
            value={environmentId}
            onChange={setEnvironmentId}
            error={isPartial && !environmentId}
          />
        </div>
        {sections.map(({ title, tooltip, update, map, options, partial }) => (
          <CollapsibleSection
            key={title}
            title={
              <>
                {title}
                {tooltip && <InlineTooltip text={tooltip} />}
              </>
            }
            collapsible={false}
          >
            <div className={styles.twoColumnContainer}>
              <div className={styles.fieldHeaderText}>Persona Attribute</div>
              <div className={styles.fieldHeaderText}>Feathery Field</div>
              {map.map((data: any, index: number) => (
                <Fragment key={`${index}`}>
                  <DropdownField
                    className='dialog-form-input'
                    selected={data.persona_attribute ?? ''}
                    onChange={(e: any) => {
                      const newParam = e.target.value;
                      update(
                        produce(map, (draft: any) => {
                          draft[index] = {
                            ...draft[index],
                            persona_attribute: newParam
                          };
                        })
                      );
                    }}
                    options={options}
                    error={partial && !data.persona_attribute}
                  />
                  <div className={styles.fieldSelectorContainer}>
                    <FieldSelectorWithModal
                      selectId={data.feathery_field}
                      selectType={data.feathery_type}
                      placeholder='Select'
                      onSelect={(field) => {
                        update(
                          produce(map, (draft: any) => {
                            draft[index] = {
                              ...draft[index],
                              feathery_field: field.selectId,
                              feathery_type: field.selectType
                            };
                          })
                        );
                      }}
                      error={partial && !data.feathery_field}
                      className={classNames(
                        styles.marginBottom,
                        styles.fieldSelector
                      )}
                    />
                    <TrashIcon
                      height={16}
                      width={16}
                      className={classNames(
                        'tr-icon',
                        styles.customPropertyDelete
                      )}
                      onClick={() => {
                        update(
                          produce(map, (draft: any) => {
                            draft.splice(index, 1);
                          })
                        );
                      }}
                    />
                  </div>
                </Fragment>
              ))}
              <PlusIcon
                className={styles.customPropertyAdd}
                onClick={() => {
                  update(
                    produce(map, (draft: any) => {
                      draft.push(defaultEntry());
                    })
                  );
                }}
              />
            </div>
          </CollapsibleSection>
        ))}
      </form>
    </IntegrationsSidebar>
  );
}

const PERSONA_PREFILL_PARAMS = [
  { value: 'referenceId', display: 'Reference ID' },
  { value: 'nameFirst', display: 'First Name' },
  { value: 'nameLast', display: 'Last Name' },
  { value: 'birthdate', display: 'Date of Birth' },
  { value: 'addressStreet1', display: 'Address Line 1' },
  { value: 'addressCity', display: 'Address City' },
  { value: 'addressSubdivision', display: 'Address State' },
  { value: 'addressPostalCode', display: 'Address Postal Code' },
  { value: 'addressCountryCode', display: 'Address Country Code' },
  { value: 'phoneNumber', display: 'Phone Number' },
  { value: 'emailAddress', display: 'Email Address' }
];

const PERSONA_SAVE_PARAMS = [
  { value: 'verificationStatus', display: 'Verification Status' },
  { value: 'idType', display: 'ID Type' },
  { value: 'idNumber', display: 'ID Number' },
  { value: 'birthdate', display: 'Date of Birth' },
  { value: 'nameFirst', display: 'First Name' },
  { value: 'nameLast', display: 'Last Name' },
  { value: 'tags', display: 'Tags' }
];

const defaultEntry = () => ({
  persona_attribute: '',
  feathery_field: '',
  feathery_type: ''
});

export default PersonaSettingsSidebar;
